
import { CameraManager, Graphics, Time, init } from "./core/ozhi-core";
import { HomeScene } from "./scenes/HomeScene";

const parent = document.body;

init(parent).then(() => 
{
    let scene = new HomeScene();
    scene.on_assets_ready();

    let last_time = performance.now();
    function animate()
    {
        requestAnimationFrame(animate);
        Time.delta_time = (performance.now() - last_time) / 1000;
        Time.delta_time = Math.min(Time.delta_time, 0.1);
        last_time = performance.now();
        scene.update();
        Graphics.renderer.render(scene, CameraManager.current);
    }
    requestAnimationFrame(animate);
}).catch((error) => 
{
    console.error(error);
});