

import { AbstractScene, CameraManager, Graphics, ResourceContainer, Time, is_portrait } from '../core/ozhi-core.js';
import { AnimationMixer, Color, PMREMGenerator, Vector3 } from 'three';

class HomeScene extends AbstractScene
{
  constructor()
  {
    super();
    this.draw_text = true;
    this.number_of_slides = 7;
    
    if(this.draw_text)
    {
      this.container = document.querySelector('.home .container');
      if(!this.container || !this.container.children[0])
      {
        this.draw_text = false;
      }
      else
      {
        this.container.children[0].classList.add( this.mode == 'slideshow' ? 'active-slide' : 'active-slide-fast');
      }
    }

    /**@type {'slideshow' | 'scroll'} */
    this.mode = location.hash == '#scroll' ? 'scroll' : 'slideshow';

    this.current_time = 0;
    this.current_slide = 0;
    this.waiting = true;
    
    
    this.hFOV = 50;
    this.camera_offset = new Vector3(0, 0, 0);
    this.camera_offset_raw = new Vector3(0, 0, 0);
    
    location.onhashchange = () => this.mode = location.hash == '#scroll' ? 'scroll' : 'slideshow';

    this.create_scroll_indicator();
    this.init_scroll();
    this.init_camera_movement();
    this.init_mobile();
  }

  init_camera_movement()
  {
    document.addEventListener('mousemove', event => 
    {
      this.camera_offset_raw.x = (event.clientX - window.innerWidth / 2) / window.innerWidth * 2;
      this.camera_offset_raw.y = (event.clientY - window.innerHeight / 2) / window.innerHeight * 2;
    });
  }

  set_slider(index, fast = false)
  {
    if(this.draw_text)
    {
      this.container.querySelector('.active-slide')?.classList.remove('active-slide');
      this.container.querySelector('.active-slide-fast')?.classList.remove('active-slide-fast');
      this.container.children[index]?.classList.add(fast ? 'active-slide-fast' : 'active-slide');
    }

    window.dispatchEvent(new CustomEvent('slide', {detail: index}));
  }

  init_scroll()
  {

    let last_scroll = 0;
    this.scroll_speed = 0;
    document.addEventListener('wheel', event =>
    {
      if((performance.now() - last_scroll)/1000 < Time.delta_time)
      {
        return;
      }
      last_scroll = performance.now();
      if (this.waiting && this.mode == 'slideshow')
      {
        this.current_time +=  Math.sign(event.deltaY);
        this.current_time = Math.max(0, this.current_time);

        if(Math.floor(this.current_time % this.number_of_slides) != this.current_slide){
          this.current_slide = Math.floor(this.current_time % this.number_of_slides);
          this.set_slider(this.current_slide);
        }
        if (this.current_time % this.number_of_slides > 1)
        {
          this.mixer.update(Math.sign(event.deltaY) * 0.25);
        }
        this.waiting = false;
      }
      else if(this.mode == 'scroll')
      {
        this.scroll_speed += Math.sign(event.deltaY) * 0.25 * Math.min(Math.abs(event.deltaY) * 0.1, 1);
      }
    });
  }

  create_scroll_indicator()
  {
    this.scroll_indicator = document.createElement('div');
    Object.assign(this.scroll_indicator.style, {
      position: 'fixed',
      bottom: '0',
      left: '0',
      width: '100%',
      height: '5px',
      background: '#0d47a1',
      zIndex: '1000',
      pointerEvents: 'none'
    });

    if(this.mode == 'scroll')
      document.body.appendChild(this.scroll_indicator);
  }

  init_mobile()
  {
    document.addEventListener('touchstart', event => this.last_touch = event.touches[0].clientY);
    document.addEventListener('touchmove', event => {
      const deltaY = event.touches[0].clientY - (this.last_touch || event.touches[0].clientY);
      document.dispatchEvent(new WheelEvent('wheel', {deltaY: -deltaY*10}));
      this.last_touch = event.touches[0].clientY;
    })

    let requesting = false;
    let set_motion = ()=>{
      window.addEventListener("devicemotion", (event) => 
      {
        this.camera_offset_raw.y = -event.rotationRate.alpha/20;
        this.camera_offset_raw.x = -event.rotationRate.beta/20;
        this.camera_offset_raw.z = -event.rotationRate.gamma/20;
        if(requesting)
        {
          window.removeEventListener('click', request_permission);
          requesting = false;
        }
      });
    }

    let request_permission = ()=>
    {
      window.removeEventListener('click', request_permission);
      requesting = false;
      DeviceMotionEvent.requestPermission()
      .then(permissionState => 
        {
        if (permissionState === 'granted')
        {
          set_motion();
        }
      })
      .catch(console.error);
    }

    if (typeof DeviceMotionEvent.requestPermission === 'function')
    {
      window.addEventListener('click', request_permission);
      requesting = true;
    }

    set_motion();
  }

  update()
  {
    CameraManager._current.position.copy(this.camera.position);
    CameraManager._current.rotation.copy(this.camera.rotation);
    const target = CameraManager._current.position.clone().add(CameraManager._current.getWorldDirection(new Vector3()).multiplyScalar(30));
    if(is_portrait)
      this.camera.fov = 20;
    CameraManager._current.fov = Math.atan(Math.tan(this.camera.fov * 1.77 * Math.PI / 360) / CameraManager._current.aspect) * 360 / Math.PI;
    CameraManager._current.updateProjectionMatrix();

    this.camera_offset.lerp(this.camera_offset_raw, Time.delta_time * 2);

    CameraManager._current.position.x -= Math.cos(this.camera.rotation.y ) * this.camera_offset.x * 2;
    CameraManager._current.position.z += Math.sin(this.camera.rotation.y ) * this.camera_offset.x * 2;
    CameraManager._current.position.y += this.camera_offset.y * 2;

    CameraManager._current.lookAt(target);
    if(this.mode == 'slideshow')
    {
      if (this.mixer.time <= this.slide_duration * this.current_time)
      {
        const speed = 1 + this.current_time * this.slide_duration - this.mixer.time;
        this.mixer.update(Time.delta_time * speed * 0.5);
      }
      else if (this.mixer.time > this.slide_duration * this.current_time)
      {
        const speed = 1 + this.mixer.time - this.current_time * this.slide_duration;
        this.mixer.update(-Time.delta_time * speed * 0.5);
      }
      
      if (Math.abs(this.current_time * this.slide_duration - this.mixer.time) < 0.1)
      {
        this.waiting = true;
      }
    } else if(this.mode == 'scroll')
    {
      this.mixer.update(this.scroll_speed * Time.delta_time);
      if(this.current_time != Math.floor((this.mixer.time / this.slide_duration) + 0.5))
      {
        this.current_time = Math.floor((this.mixer.time / this.slide_duration) + 0.5);
        if(this.current_time % this.number_of_slides != this.current_slide){
          this.current_slide = Math.floor(this.current_time % this.number_of_slides);
          this.set_slider(this.current_slide, true);
        }
      }
      this.scroll_speed *= 0.8;
      this.scroll_indicator.style.width = `${((this.mixer.time % this.total_duration) / this.total_duration) * 100}%`;
    }
  }

  on_assets_ready()
  {

    this.background = new Color(0x000000);

    const pmremGenerator = new PMREMGenerator(Graphics._renderer);
    pmremGenerator.compileEquirectangularShader();
    const envMap = pmremGenerator.fromEquirectangular(ResourceContainer.get('envMap')).texture;
    this.environment = envMap;

    /** @type {THREE.Object3D} */
    const scene = ResourceContainer.get('scene').scene;

    /** @type {THREE.Object3D} */
    const lights = ResourceContainer.get('lights').scene;
    scene.add(lights);

    /** @type {THREE.Object3D} */
    const camera_animation = ResourceContainer.get('camera_animation');

    this.add(scene);

    scene.traverse((/** @type {THREE.Mesh} */child) =>
    {
      child.matrixAutoUpdate = false;
      child.updateMatrix();
      if (child.isLight)
      {
        child.intensity = 0.3;
        child.distance = 50;
        child.color.setHex(0x0000ff);
      }
      if (child.isMesh)
      {
        /** @type {THREE.MeshStandardMaterial} */
        const material = child.material;
        material.roughness = 0.5;
        material.metalness = 0;
        material.emissiveIntensity = 0;
        material.envMapIntensity = 1;

        material.onBeforeCompile = (shader) =>
        {
          shader.fragmentShader = `
          float sigmoid(float x){
            return x / (1. + abs(x));
          }
          vec3 sigmoid(vec3 x){
            return vec3(sigmoid(x.x), sigmoid(x.y), sigmoid(x.z));
          }
          ` + shader.fragmentShader;
          shader.fragmentShader = shader.fragmentShader.replace('#include <transmission_fragment>', `
          totalDiffuse = reflectedLight.directDiffuse;
          totalSpecular.rgb = sigmoid(pow(totalSpecular  * 20., vec3(2.)) * totalDiffuse.b);
          totalSpecular = sigmoid(totalSpecular);
          totalSpecular.rg = vec2(0.);
          totalDiffuse.rg = vec2(0.);
          #include <transmission_fragment>`);
        };
        material.needsUpdate = true;
      }

      if (child.name == 'fondo')
      {
        child.visible = false;
      }
    });

    /** @type {THREE.PerspectiveCamera} */
    this.camera = camera_animation.cameras[0];

    this.mixer = new AnimationMixer(camera_animation.scene);
    this.action = this.mixer.clipAction(camera_animation.animations[0]);
    this.action.play();
    this.slide_duration = this.action.getClip().duration / this.number_of_slides;
    this.total_duration = this.action.getClip().duration;
  }
}

export { HomeScene };
