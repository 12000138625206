
import { PerspectiveCamera, SRGBColorSpace, Scene, TextureLoader, WebGLRenderer } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';

const is_portrait = window.innerWidth < window.innerHeight;

/**@type {{_current: THREE.PerspectiveCamera, current: THREE.PerspectiveCamera}}}*/
const  CameraManager = {
    _current: null,
    current: null,
}

/**@type {{_renderer: THREE.WebGLRenderer, renderer: THREE.WebGLRenderer}}}*/
const Graphics = {
    _renderer: null,
    renderer: null,
}

/**@type {{get: (str:string) => THREE.Scene}}}*/
const ResourceContainer = {
    get: (str) => ResourceContainer[str]
}

/**@type {{delta_time: number}}}*/
const Time = {
    delta_time: 1/60
}

const objects = [
    {
      name: 'scene',
      url:  'models/scene.glb',
      size: 2000,
      draco: false
    },
    {
      name: 'lights',
      url: 'models/lights.glb',
      size: 2000,
      draco: false
    },
    {
      name: 'camera_animation',
      url: is_portrait ? 'models/ANIM_MOBILE_CAM_02.glb' : 'models/ANIM_06_CAM.glb',
      size: 2000,
      draco: false
    }
];

const textures = [
    {
      name: 'envMap',
      url: 'textures/enviroment.hdr',
      size: 2000,
      kind: 'hdr'
    }
];

async function init(parent = document.body)
{
    let loaded_size = 0;

    const loading_bar = document.createElement('div');
    Object.assign(loading_bar.style, {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '0%',
        height: '5px',
        backgroundColor: '#0d47a1',
        transition: 'width 0.5s'
    });
    document.body.appendChild(loading_bar);

    const loader = new GLTFLoader();
    const promises = [];
    for (const object of objects) {
        promises.push(new Promise((resolve, reject) => 
        {
            loader.load(object.url, (gltf) => {
                ResourceContainer[object.name] = gltf;
                resolve();
            }, () => {
                const s = (1 - loaded_size);
                loaded_size += (s * s * s) * 0.1;
                loading_bar.style.width = `${loaded_size * 100}%`;
            }, (error) => {
                reject(error);
            });
        }));
    }

    for (const texture of textures) 
    {
        promises.push(new Promise((resolve, reject) => 
        {
            if (texture.kind == 'hdr'){
                new RGBELoader().load(texture.url, (_texture) => {
                    ResourceContainer[texture.name] = _texture;
                    resolve();
                }, () => {
                    const s =  (1 - loaded_size);
                    loaded_size += (s * s * s) * 0.1;
                    loading_bar.style.width = `${loaded_size * 100}%`;
                }, (error) => {
                    console.error(error);
                    reject(error);
                });
            }else{
                new TextureLoader().load(texture.url, (_texture) => {
                    ResourceContainer[texture.name] = _texture;
                    resolve();
                }, () => {
                    const s =  (1 - loaded_size);
                    loaded_size += (s * s * s) * 0.1;
                    loading_bar.style.width = `${loaded_size * 100}%`;
                }, (error) => {
                    console.error(error);
                    reject(error);
                });
            }
        }));
    }

    loading_bar.style.width = '100%';

    await Promise.all(promises);

    Graphics._renderer = new WebGLRenderer({ antialias: true });
    Graphics._renderer.setPixelRatio(1);
    Graphics._renderer.autoClear = false;
    Graphics._renderer.setSize(window.innerWidth, window.innerHeight);

    Graphics._renderer.outputColorSpace = SRGBColorSpace;
    Graphics._renderer.physicallyCorrectLights = false;
    CameraManager._current = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    CameraManager._current.position.z = 5;
    CameraManager.current = CameraManager._current;
    Graphics.renderer = Graphics._renderer;

    addEventListener("resize", () => {
        Graphics._renderer.setSize(window.innerWidth, window.innerHeight);
        CameraManager._current.aspect = window.innerWidth / window.innerHeight;
        CameraManager._current.updateProjectionMatrix();
    });

    Graphics._renderer.domElement.style.opacity = '0';
    Graphics._renderer.domElement.style.transition = 'opacity 1.5s';
    loading_bar.style.transition = 'opacity 1.5s';
    parent.appendChild(Graphics._renderer.domElement);
    setTimeout(() => 
    {
        Graphics._renderer.domElement.style.opacity = '1';
        loading_bar.style.opacity = '0';
        setTimeout(() => 
        {
            loading_bar.remove();
        }, 1500);
    }, 500);
}

class AbstractScene extends Scene {
    constructor(){super();}
    update(){}
    on_assets_ready(){}
    init(){}
}

export {
    CameraManager,
    Graphics,
    ResourceContainer,
    Time,
    AbstractScene,
    init,
    is_portrait
}